import { IPrintDocContent } from '@/components/order/collect-order/interfaces'

export function getOrderContent(props: IPrintDocContent): string {
	let tbody = ``
	// все записи кроме последней где хранится итоги заказа
	for (let i = 0; i < props.variations.slice(0, -1).length; i++) {
		const variation = props.variations[i]
		tbody = `${tbody}
			<tr align="center">
				<td>${variation.article}</td>
				<td>${variation.name}</td>
				<td>${variation.count} ед.</td>
				<td>${variation.basePrice} ₽</td>
				<td>${variation.price} ₽</td>
			</tr>
		`
	}

	const totalVariations = props.variations[props.variations.length - 1]
	const tfoot = `
		<tr>
			<th>${totalVariations.article}</th>
			<th>${totalVariations.name}</th>
			<th>${totalVariations.count} ед.</th>
			<th>${totalVariations.basePrice} ₽</th>
			<th>${totalVariations.price} ₽</th>
		</tr>
	`

	return `<!DOCTYPE html>
	<html>
		<head>
			<title>Состав заказа №${props.orderId}</title>

			<style>
				* {
					margin: 0;
					padding: 0;
					box-sizing: border-box;
				}

				h1 {
					text-align: center;
					font-size: 36px;
					font-weight: 700;
					margin-bottom: 30px;
				}
				th, td {
					padding: 10px;
				}
			</style>
		</head>

		<body>
			<h1>Состав заказа №${props.orderId}</h1>
			<table border="1">
			  <thead>
					<tr>
						<th>Артикул</th>
						<th>Наименование</th>
						<th>Количество</th>
						<th>Сумма без скидки</th>
						<th>Сумма со скидкой</th>
					</tr>
				</thead>
				<tbody>
				 ${tbody}
				</tbody>
				<tfoot>
				 ${tfoot}
				</tfoot>
			</table>
		</body>
	</html>`
}
