
import { computed, defineComponent, inject, Ref } from 'vue'
import { enumOrdersState, enumOrderVariationsState, localizeOrderVariationState } from '@/constains'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from '@/http'
import MoveMaster from '@/components/storage/MoveMaster.vue'
import { AxiosResponse } from 'axios'
import { ICollectOrder, IFile } from '@/interfaces'
import { IPrintDocContentVariationItem, IPrintDocContentVariations } from '@/components/order/collect-order/interfaces'
import { getOrderContent } from '@/components/order/collect-order/getOrderContent'
import { getUrlFile } from '@/utils/getUrlImg'

export default defineComponent({
	name: 'CollectOrderStep',

	components: { MoveMaster },

	setup() {
		const order = inject<Ref<ICollectOrder> | undefined>('order')

		const movedCount = computed(() => order?.value.ordersVariations.reduce((acc, el) => acc + el.collected, 0))
		const movedStr = computed(() => movedCount.value + '/' + order?.value.units)

		const countProduct = computed(() => {
			const productIds: { [key: string]: null } = {}

			order?.value.ordersVariations.forEach(el => {
				productIds[el.variation.product.id] = null
			})

			return Object.keys(productIds).length
		})

		const isAllVarCollected = computed(() => (order?.value.units || 0) >= countProduct.value)

		const countProductsVariationsStr = computed(() => countProduct.value + 'тов, ' + order?.value.units + 'шт')

		const getListProducts = computed(() => {
			return order?.value.ordersVariations.map(item => {
				return {
					id: item.variation.id,
					photo: getUrlPhoto(
						item.variation.product.mediaIdMedia.find(elP => elP.ProductsMedia.o === 1) ||
							item.variation.product.mediaIdMedia[0]
					),
					state: getLocalizeStateOrderVariation(item.state),
					category: item.variation.product.type.name,
					brand: item.variation.product?.brand?.name,
					name: item.variation.product?.name,
					size: item.variation.sizeType.name + ' ' + item.variation.sizeValue.name,
					colors: item.variation.product.productsColors.map(el => el.color.name).join(', '),
					storages: item.variation.storages
						.filter(itemSt => itemSt.cell.isPhysical)
						.map(elS => ({ cellKey: elS.cell.key, count: elS.holded })),
					collected: item.collected + '/' + item.holded
				}
			})
		})

		function getUrlPhoto(media: IFile) {
			const project = process.env.VUE_APP_ENV === 'dev' ? 'dev' : 'production'

			return getUrlFile({
				entityType: media.entityType,
				state: media.state,
				project,
				extension: media.extension,
				size: 's',
				id: media.id,
				server: media.server
			})
		}

		function getLocalizeStateOrderVariation(state: enumOrderVariationsState) {
			const key = enumOrderVariationsState[state] as keyof typeof enumOrderVariationsState

			return localizeOrderVariationState[key]
		}

		async function toCollecting() {
			ElMessageBox.confirm('Вы точно хотите приступить к сборке этого заказа?', '', {
				boxType: 'confirm',
				type: 'info',
				confirmButtonText: 'Подтвердить',
				cancelButtonText: 'Отменить'
			}).then(async () => {
				try {
					if (order?.value) await apiToCollecting(Number(order?.value.id))

					if (order?.value.state) order.value.state = enumOrdersState.collecting
				} catch (e) {
					ElMessage.error('Не удалось перевести в статус сборки')
				}
			})
		}

		function printOrderContent() {
			if (!order?.value) return
			const propsForPrint: IPrintDocContentVariations = []
			const totalOrderContent: IPrintDocContentVariationItem = {
				article: 'Итого',
				name: '',
				count: 0,
				basePrice: 0,
				price: 0
			}
			for (const ordersVariation of order.value.ordersVariations) {
				const article = ordersVariation.variationId
				const variation = ordersVariation.variation
				const product = ordersVariation.variation.product
				const name =
					product.name ||
					`${product.type.name} ${product?.brand?.name || ''} ${variation.sizeType.name} ${
						variation.sizeValue.name
					} ${product.productsColors.map(color => color.color.name).join(', ')}`
				const propsForPrintItem = {
					article,
					name,
					count: ordersVariation.collected,
					basePrice: isNaN(Number(ordersVariation.basePrice)) ? 0 : Number(ordersVariation.basePrice),
					price: isNaN(Number(ordersVariation.price)) ? 0 : Number(ordersVariation.price)
				}
				propsForPrint.push(propsForPrintItem)
				totalOrderContent.basePrice += propsForPrintItem.basePrice
				totalOrderContent.count += propsForPrintItem.count
				totalOrderContent.price += propsForPrintItem.price
			}
			propsForPrint.push(totalOrderContent)
			const orderContent = getOrderContent({
				orderId: order.value.id,
				variations: propsForPrint
			})
			const popupWindow = window.open()
			popupWindow?.document.write(orderContent)

			setTimeout(function () {
				popupWindow?.focus()
				popupWindow?.print()
				popupWindow?.close()
			}, 0)
		}

		async function apiToCollecting(id: number) {
			await axios.get('/order/change-paid-collecting-by-id/' + id)
		}

		function success(data: { successData: AxiosResponse<ICollectOrder> }) {
			if (order?.value) order.value = data.successData.data
		}

		return {
			order,
			movedStr,
			enumOrdersState,
			countProductsVariationsStr,
			getListProducts,
			isAllVarCollected,

			toCollecting,
			printOrderContent,
			success
		}
	}
})
