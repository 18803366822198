import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectInfo = _resolveComponent("CollectInfo")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_OrderPaying = _resolveComponent("OrderPaying")!
  const _component_CollectOrderStep = _resolveComponent("CollectOrderStep")!
  const _component_CollectPreparation = _resolveComponent("CollectPreparation")!
  const _component_CollectDelivery = _resolveComponent("CollectDelivery")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tabs, {
      lazy: "",
      "before-leave": _ctx.onChangeTab,
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
      type: "card",
      class: "demo-tabs"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, { label: "Информация" }, {
          default: _withCtx(() => [
            _createVNode(_component_CollectInfo, {
              order: _ctx.order,
              onUpdatedOrder: _ctx.updatedOrder
            }, null, 8, ["order", "onUpdatedOrder"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, { label: "Оплата" }, {
          default: _withCtx(() => [
            _createVNode(_component_OrderPaying, {
              order: _ctx.order,
              onUpdatedOrder: _ctx.updatedOrder
            }, null, 8, ["order", "onUpdatedOrder"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          lazy: "",
          label: "Сборка"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CollectOrderStep)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          lazy: "",
          label: "Подготовка"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CollectPreparation)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          lazy: "",
          label: "Отправка"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CollectDelivery)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["before-leave", "modelValue"])
  ]))
}