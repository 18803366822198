
import { defineComponent, inject, onMounted, ref, Ref } from 'vue'
import { ICollectOrder } from '@/interfaces'
import { enumOrdersState } from '@/constains'
import CollectOrderStep from '@/components/order/collect-order/CollectOrderStep.vue'
import CollectPreparation from '@/components/order/collect-order/CollectPreparation.vue'
import CollectDelivery from '@/components/order/collect-order/CollectDelivery.vue'
import CollectInfo from '@/components/order/collect-order/CollectInfo.vue'
import OrderPaying from '@/components/order/OrderPaying.vue'
import { useStore } from 'vuex'

export default defineComponent({
	name: 'CollectOrderWrap',

	components: { OrderPaying, CollectDelivery, CollectPreparation, CollectOrderStep, CollectInfo },

	setup() {
		const store = useStore()
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const order = inject<Ref<ICollectOrder> | undefined>('order')
		const apiGetOrderById = inject<(id: number) => Promise<ICollectOrder | undefined> | undefined>('apiGetOrderById')

		const activeTab = ref()

		onMounted(() => {
			activeTab.value = getActiveTab()
		})

		async function updatedOrder() {
			const res = await apiGetOrderById?.(Number(order?.value.id))
			if (res && order?.value) order.value = res
		}

		function onChangeTab(activeName: string) {
			if (!checkRole) return
			const userRoleIds: number[] = store.state.auth?.userRoleIds
			let allowedIds: number[] = []
			if (activeName === '1') {
				allowedIds = [2, 7, 9]
			} else if (activeName === '2') {
				allowedIds = [7, 9]
			} else if (activeName === '3') {
				allowedIds = [7, 8, 9]
			} else if (activeName === '4') {
				allowedIds = [8, 9]
			} else {
				return
			}
			const canEnter = userRoleIds.some(id => allowedIds.includes(id))
			if (!canEnter) {
				checkRole(false)
				return
			}
		}

		function getActiveTab(): string {
			if (
				[enumOrdersState.ready, enumOrdersState.closed, enumOrdersState.shipped, enumOrdersState.received].includes(
					order?.value.state || -1
				)
			)
				return '4'

			if (
				[enumOrdersState.tracked, enumOrdersState.packing, enumOrdersState.collected].includes(order?.value.state || -1)
			)
				return '3'

			if (
				[enumOrdersState.tracked, enumOrdersState.packing, enumOrdersState.collected].includes(order?.value.state || -1)
			)
				return '3'

			if ([enumOrdersState.paid, enumOrdersState.collecting].includes(order?.value.state || -1)) return '1'

			return '0'
		}

		return { activeTab, order, updatedOrder, onChangeTab }
	}
})
