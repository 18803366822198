import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MovingOrderBoxes = _resolveComponent("MovingOrderBoxes")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.state === _ctx.enumTypesOrder.notAvailable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Шаг недоступен"))
      : _createCommentVNode("", true),
    (_ctx.state === _ctx.enumTypesOrder.delivered)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.getForDeliveredState), 1))
      : _createCommentVNode("", true),
    (_ctx.state === _ctx.enumTypesOrder.readyDelivery)
      ? (_openBlock(), _createBlock(_component_MovingOrderBoxes, {
          key: 2,
          "prop-order-id": Number(_ctx.order?.id),
          onSuccess: _ctx.successMovingOrderBoxes
        }, null, 8, ["prop-order-id", "onSuccess"]))
      : _createCommentVNode("", true)
  ]))
}