import { IPrintDocOrder } from '@/components/order/collect-order/interfaces'
import JsBarcode from 'jsbarcode'

export function getOrderDoc(props: IPrintDocOrder): string {
	const orderImgEl = document.createElement('img')
	const boxImgEl = document.createElement('img')

	JsBarcode(orderImgEl, props.orderId.toString(), {
		displayValue: false,
		height: 40,
		margin: 0
	})

	JsBarcode(boxImgEl, props.orderId + '-' + props.boxNumber, {
		displayValue: false,
		height: 40,
		margin: 0
	})

	return `<!DOCTYPE html>
	<html>
		<head>
			<title>Накладная</title>

			<style>
				* {
					margin: 0;
					padding: 0;
					box-sizing: border-box;
				}

				body {
					height: 100vh;
					padding: 84px 0;
					font-family: sans-serif;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}

				h1 {
					text-align: center;
					font-size: 36px;
					font-weight: 700;
					margin-bottom: 40px;
				}

				.img-block {
					display: flex;
					justify-content: center;
				}

				.middle {
					border-top: 1px solid #000000;
					border-bottom: 1px solid #000000;
					padding: 0 30px;
				}

				.middle__el {
					border-bottom: 1px solid #F5F5F5;
					padding: 16px 0;
					display: flex;
					justify-content: space-between;
				}

				.middle__el-title {
					font-weight: 600;
					font-size: 15px;
					color: #222222;
				}

				.middle__el-val {
					color: #555555;
					font-weight: 400;
					font-size: 15px;
					max-width: 300px;
					text-align: right;
				}
			</style>
		</head>

		<body>
			<div>
				<h1>Заказ №${props.orderId}</h1>

				<div class="img-block">
					${orderImgEl.outerHTML}
				</div>
			</div>

			<div class="middle">
				<div class="middle__el">
					<div class="middle__el-title">Тип доставки</div>
					<div class="middle__el-val">${props.typeDelivery}</div>
				</div>

				<div class="middle__el">
					<div class="middle__el-title">Накладная</div>
					<div class="middle__el-val">${props.trackNumber}</div>
				</div>

				<div class="middle__el">
					<div class="middle__el-title">Адрес</div>
					<div class="middle__el-val">${props.fullAddress}</div>
				</div>

				<div class="middle__el">
					<div class="middle__el-title">Получатель</div>
					<div class="middle__el-val">${props.recipient}</div>
				</div>

				<div class="middle__el">
					<div class="middle__el-title">Телефон</div>
					<div class="middle__el-val">${props.phone}</div>
				</div>
			</div>

			<div>
				<h1>Коробка ${props.boxNumber} из ${props.countBox}</h1>

				<div class="img-block">
					${boxImgEl.outerHTML}
				</div>
			</div>
		</body>
	</html>`
}
