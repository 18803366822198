
import { defineComponent, onMounted, provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/http'
import { enumOrdersState, localizeOrderState } from '@/constains'
import CollectOrderWrap from '@/components/order/collect-order/index.vue'
import { ICollectOrder } from '@/interfaces'

export default defineComponent({
	name: 'OrderById',

	components: { CollectOrderWrap },

	setup() {
		const order = ref<ICollectOrder>()
		const route = useRoute()
		const router = useRouter()

		provide('order', order)

		onMounted(() => {
			getOrderById(route.params.id as string)
		})

		async function getOrderById(id: string) {
			const res = await apiGetOrderById(Number(id))
			if (!res) return router.push({ name: 'Error' })

			order.value = res
		}

		async function apiGetOrderById(id: number): Promise<ICollectOrder | undefined> {
			try {
				return (await axios.get('/order/full-by-id/' + id)).data
			} catch (e) {}
		}

		provide('apiGetOrderById', apiGetOrderById)

		function getLocalizeState(state: enumOrdersState) {
			const key = enumOrdersState[state] as keyof typeof enumOrdersState

			return localizeOrderState[key]
		}

		return { order, enumOrdersState, getLocalizeState }
	}
})
