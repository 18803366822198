
import { computed, defineComponent, inject, Ref } from 'vue'
import { ICollectOrder } from '@/interfaces'
import { enumDeliveryTypes, enumOrdersState, localizeDeliveryTypes, localizeOrderState } from '@/constains'
import MovingOrderBoxes from '@/components/order/MovingOrderBoxes.vue'
import { ElMessage } from 'element-plus/es'

enum enumTypesOrder {
	notAvailable,
	readyDelivery,
	delivered
}

export default defineComponent({
	name: 'CollectDelivery',

	components: { MovingOrderBoxes },

	setup() {
		const order = inject<Ref<ICollectOrder> | undefined>('order')
		const apiGetOrderById = inject<(id: number) => Promise<ICollectOrder | undefined> | undefined>('apiGetOrderById')

		const state = computed(() => {
			if (
				[enumOrdersState.closed, enumOrdersState.shipped, enumOrdersState.received].includes(order?.value.state || -1)
			)
				return enumTypesOrder.delivered

			if ([enumOrdersState.ready].includes(order?.value.state || -1)) return enumTypesOrder.readyDelivery

			return enumTypesOrder.notAvailable
		})

		const getForDeliveredState = computed(() => {
			if (order?.value.ordersDeliveries[0].type !== enumDeliveryTypes.warehouse)
				return (
					getLocalizeStateOrder(order?.value.state || -1) +
					'\n' +
					getLocalizeStateTypeDelivery(order?.value.ordersDeliveries[0].type || -1) +
					' ' +
					order?.value.ordersDeliveries[0].tracking
				)

			return getLocalizeStateOrder(order?.value.state || -1)
		})

		function getLocalizeStateTypeDelivery(state: enumDeliveryTypes) {
			const key = enumDeliveryTypes[state] as keyof typeof enumDeliveryTypes

			return localizeDeliveryTypes[key]
		}

		function getLocalizeStateOrder(state: enumOrdersState) {
			const key = enumOrdersState[state] as keyof typeof enumOrdersState

			return localizeOrderState[key]
		}

		async function successMovingOrderBoxes() {
			try {
				const res = await apiGetOrderById?.(Number(order?.value.id))
				if (res && order?.value) order.value = res
			} catch (e) {
				ElMessage.error('Не удалось обновить заказ, обновите страницу')
			}
		}

		return {
			order,
			state,
			enumTypesOrder,
			getForDeliveredState,
			successMovingOrderBoxes
		}
	}
})
