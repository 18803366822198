
import { computed, defineComponent, inject, PropType, ref, toRef } from 'vue'
import { IOrderWithPayment } from '@/interfaces'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from '@/http'
import { useStore } from 'vuex'
import { enumOrdersState, enumOrderPaymentState, enumOrderPaymentType } from '@/constains'

interface IUploadFile {
	name: string
	uid: number
	url: string
	raw: File
	created?: Date
	id: string
}

export default defineComponent({
	name: 'OrderPaying',

	props: {
		order: {
			type: Object as PropType<IOrderWithPayment>,
			required: true
		}
	},

	emits: ['updatedOrder'],

	setup(props, ctx) {
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const store = useStore()
		const userRoleIds = store.state?.auth?.userRoleIds
		const order = toRef(props, 'order')
		const selectedDate = ref(order.value.orderPayment.expiring)
		const isFetchingConfirm = ref(false)
		const isDisableConfirm = computed(
			() => ![enumOrdersState.created, enumOrdersState.creating].includes(order.value?.state)
		)
		const isCanCancel = computed(() => order.value?.state === enumOrdersState.confirmed)
		const isCanCancelPaidOrder = computed(() => order.value?.state === enumOrdersState.paid)
		const isPaymentStateRefunding = computed(() => order.value?.orderPayment?.state === enumOrderPaymentState.refunding)
		const isPaymentStateRefunded = computed(() => order.value?.orderPayment?.state === enumOrderPaymentState.refunded)
		const isPaymentTypeBill = computed(() => order.value?.orderPayment?.type === enumOrderPaymentType.bill)
		const isPaymentTypeCardTinkoff = computed(
			() => order.value?.orderPayment?.type === enumOrderPaymentType.card_tinkoff
		)
		const isCanChangeDate = computed(() =>
			[enumOrdersState.created, enumOrdersState.confirmed].includes(order.value?.state)
		)

		const refundDoc = computed(() => order.value?.orderPayment?.refundDoc)
		const isFetchingCancel = ref(false)
		const isFetchingDate = ref(false)

		async function confirmOrder() {
			if (!checkRole) return
			const allowedRoles = [2, 9]
			let isUserCanChange = false
			for (const userRoleId of userRoleIds) {
				if (allowedRoles.includes(userRoleId)) {
					isUserCanChange = true
				}
			}
			if (!isUserCanChange) checkRole(false)
			isFetchingConfirm.value = true

			try {
				await apiConfirmOrder(order.value.id)

				ctx.emit('updatedOrder')
			} catch (e) {
				ElMessage.error('Не удалось перевести статус в подтвержден')
			}

			isFetchingConfirm.value = false
		}

		async function cancelOrder() {
			if (!checkRole) return

			if (!userRoleIds.includes(9)) checkRole(false)
			try {
				const confirmAnswer = await ElMessageBox.confirm('Вы точно хотите отменить заказ?', 'Warning', {
					confirmButtonText: 'Продолжить',
					cancelButtonText: 'Отмена',
					type: 'warning'
				})
				if (!confirmAnswer) return
				isFetchingCancel.value = true
				await apiCancelOrder(order.value.id)

				ctx.emit('updatedOrder')
			} catch (e) {
				ElMessage.error('Не удалось перевести статус в отменен')
			}

			isFetchingCancel.value = false
		}

		function confirmCancelPaidOrder() {
			if (!checkRole) return
			if (!userRoleIds.includes(9)) checkRole(false)
			ElMessageBox.confirm('Вы точно хотите сделать отмену? Подумайте дважды!', {
				boxType: 'confirm',
				type: 'info',
				confirmButtonText: 'Продолжить',
				cancelButtonText: 'Отменить'
			}).then(() => cancelPaidOrder())
		}

		async function cancelPaidOrder() {
			try {
				isFetchingCancel.value = true
				await apiCancelOrderPaid(order.value.id)

				ctx.emit('updatedOrder')
				ElMessage.success('Переведено в статус - отменен')
			} catch (e) {
				ElMessage.error('Не удалось перевести статус в отменен')
			}

			isFetchingCancel.value = false
		}
		async function uploadRefundDoc(file: IUploadFile) {
			const rawFile = file.raw
			const formData = new FormData()
			formData.append('id', String(order?.value?.id))
			formData.append('files', rawFile)
			await axios.post(process.env.VUE_APP_URL_MAIN_BACK_API + '/media/refund-doc', formData)
			ctx.emit('updatedOrder')
		}

		async function apiConfirmOrder(id: string) {
			await axios.get('/order/confirm-order-by-id/' + id)
		}
		async function apiCancelOrder(id: string) {
			await axios.get('/order/cancel-order-by-id/' + id)
		}
		async function apiCancelOrderPaid(id: string) {
			await axios.get('/order/cancel-order-paid-by-id/' + id)
		}

		async function changeExpiring() {
			if (!checkRole) return
			const allowedRoles = [2, 9]
			let isUserCanChange = false
			for (const userRoleId of userRoleIds) {
				if (allowedRoles.includes(userRoleId)) {
					isUserCanChange = true
				}
			}
			if (!isUserCanChange) checkRole(false)
			try {
				isFetchingDate.value = true
				if (order.value.orderPayment.expiring === selectedDate.value) return
				var tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
				var localISOTime = new Date(selectedDate.value.getTime() - tzoffset).toISOString()
				const data = {
					expiring: localISOTime,
					id: order.value?.orderPayment.id || ''
				}
				await apiChangeExpiringOrderPayment(data)

				ctx.emit('updatedOrder')
				ElMessage.success('Время успешно обновлено')
			} catch (e) {
				ElMessage.error('Не удалось сменить время')
			} finally {
				isFetchingDate.value = false
			}
		}

		async function apiChangeExpiringOrderPayment(data: { expiring: string; id: string }) {
			await axios.post('/order/update-expiring-order-payment', data)
		}

		return {
			isDisableConfirm,
			isFetchingConfirm,
			isCanCancel,
			isCanCancelPaidOrder,
			isPaymentStateRefunding,
			isPaymentStateRefunded,
			isPaymentTypeBill,
			isPaymentTypeCardTinkoff,
			refundDoc,

			isCanChangeDate,
			selectedDate,
			isFetchingDate,
			isFetchingCancel,

			confirmCancelPaidOrder,
			confirmOrder,
			uploadRefundDoc,
			changeExpiring,
			cancelOrder
		}
	}
})
