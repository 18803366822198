
import { defineComponent, inject, watchEffect, ref, Ref, computed } from 'vue'
import { ICollectOrder } from '@/interfaces'

interface ILot {
	name: string
	ordered: number
	collected: number
	price: number
}

export default defineComponent({
	setup() {
		const order = inject<Ref<ICollectOrder> | undefined>('order')

		const lots = ref<Array<ILot>>([])

		const managerName = computed(() => order?.value?.manager?.user?.nameFirst)
		const transporterName = computed(() => order?.value?.ordersDeliveries?.[0]?.transporter?.name)

		const dimensions = computed(() => {
			const ordersDelivery = order?.value?.ordersDeliveries?.[0]
			if (ordersDelivery) {
				let size = ''
				const { sizeLength, sizeWidth, sizeHeight } = ordersDelivery
				if (sizeLength) {
					size = `Д ${sizeLength.toString()}`
				}
				if (sizeWidth) {
					size += size ? ` x Ш ${sizeWidth.toString()}` : sizeWidth.toString()
				}
				if (sizeHeight) {
					size += size ? ` x В ${sizeHeight.toString()}` : sizeHeight.toString()
				}
				if (!size)
					return {
						size: null
					}
				return {
					size,
					isFullSize: !!(sizeLength && sizeWidth && sizeHeight)
				}
			}
			return {
				size: null
			}
		})

		const address = computed(() => {
			const ordersDelivery = order?.value?.ordersDeliveries?.[0]
			if (!ordersDelivery) return ''
			let htmlCode = ``
			const { postalCode, region, city, address } = ordersDelivery
			htmlCode += postalCode || '<span style="color: tomato;">Индекс</span>'
			htmlCode += region ? ` ${region}` : ' <span style="color: tomato;">Регион</span>'
			htmlCode += city ? ` ${city}` : ' <span style="color: tomato;">Населенный пункт</span>'
			htmlCode += address ? ` ${address}` : ' <span style="color: tomato;">Улица и дом</span>'
			return htmlCode
		})

		watchEffect(() => {
			if (order?.value) {
				for (const orderVariation of order?.value.ordersVariations) {
					const lot = orderVariation.variation.product.lot
					const lotName = lot.name
					const idx = lots.value.findIndex(lot => lot.name === lotName)
					if (idx !== -1) {
						lots.value[idx].ordered += orderVariation.ordered
						lots.value[idx].collected += orderVariation.collected
						lots.value[idx].price += +orderVariation.price
					} else {
						lots.value.push({
							name: lotName,
							ordered: orderVariation.ordered,
							collected: orderVariation.collected,
							price: +orderVariation.price
						})
					}
				}
			}
		})

		enum enumPaymentState {
			'Не оплачено' = 1,
			'Оплачено',
			'Отменено',
			'Просрочено',
			'Ошибка',
			'Идет возврат',
			'Возврат выполнен'
		}

		enum enumPaymentType {
			'По счету' = 1,
			'Картой'
		}

		enum enumDeliveryState {
			'Подготовка груза' = 1,
			'Создана накладная',
			'Отправлено',
			'Получено',
			'Ошибка',
			'Отменено'
		}

		enum enumDeliveryType {
			'Самовывоз' = 1,
			'СДЭК',
			'По согласованию с менеджером'
		}
		enum enumPaymentInternalDetailType {
			'Физ. лицо' = 0,
			'Юр. лицо'
		}

		return {
			order,
			managerName,
			transporterName,
			dimensions,
			address,
			lots,
			enumPaymentState,
			enumPaymentType,
			enumDeliveryState,
			enumDeliveryType,
			enumPaymentInternalDetailType
		}
	}
})
