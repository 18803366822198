
import { computed, defineComponent, inject, onMounted, reactive, ref, Ref } from 'vue'
import { enumDeliveryTypes, enumOrdersState, localizeDeliveryTypesDoc } from '@/constains'
import { ICollectOrder, ITransporter } from '@/interfaces'
import axios from '@/http'
import { ElMessage } from 'element-plus'
import { AxiosResponse } from 'axios'
import MovingOrderBoxes from '@/components/order/MovingOrderBoxes.vue'
import { IPrintDocOrder } from '@/components/order/collect-order/interfaces'
import { getOrderDoc } from '@/components/order/collect-order/getOrderDoc'
import { Check } from '@element-plus/icons-vue'
import { useStore } from 'vuex'

export interface IWeightCountBox {
	countBoxes: null | string
	weightBoxes: null | string
	sizeLength: null | string
	sizeWidth: null | string
	sizeHeight: null | string
	transporterId: null | string
	tracking: null | string
	price: null | string
	postalCode: null | string
	region: null | string
	city: null | string
	address: null | string
}

export default defineComponent({
	name: 'CollectPreparation',

	components: { MovingOrderBoxes, Check },

	setup() {
		const store = useStore()
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const userRoleIds = store.state?.auth?.userRoleIds
		const inputsWeightAndCount = reactive<IWeightCountBox>({
			countBoxes: null,
			weightBoxes: null,
			sizeLength: null,
			sizeWidth: null,
			sizeHeight: null,
			transporterId: null,
			tracking: null,
			price: null,
			postalCode: null,
			region: null,
			city: null,
			address: null
		})

		const isFetchingFullOrderToSending = ref(false)

		const checkboxesBox = ref<Array<number>>([])

		const transporters = ref<Array<ITransporter>>()

		const isDisabledCheckboxesBox = computed(() => order?.value.state !== enumOrdersState.tracked)

		const isPrintDoc = computed(() => !!order?.value.ordersDeliveries[0].boxes)

		const isFetchingWeightCountBoxes = ref(false)

		const order = inject<Ref<ICollectOrder> | undefined>('order')
		const apiGetOrderById = inject<(id: number) => Promise<ICollectOrder | undefined> | undefined>('apiGetOrderById')

		const isReadySteps = computed(() => {
			return {
				isCreatedDoc: [
					enumOrdersState.tracked,
					enumOrdersState.ready,
					enumOrdersState.shipped,
					enumOrdersState.received,
					enumOrdersState.closed,
					enumOrdersState.packing
				].includes(order?.value.state || -1),

				isPrintedDocs: [
					enumOrdersState.ready,
					enumOrdersState.closed,
					enumOrdersState.shipped,
					enumOrdersState.received,
					enumOrdersState.packing
				].includes(order?.value.state || -1),

				isMovedBoxes: [
					enumOrdersState.ready,
					enumOrdersState.closed,
					enumOrdersState.shipped,
					enumOrdersState.received
				].includes(order?.value.state || -1)
			}
		})

		onMounted(async () => {
			const orderDelivery = order?.value.ordersDeliveries[0]
			inputsWeightAndCount.countBoxes = String(orderDelivery?.boxes) || null
			inputsWeightAndCount.weightBoxes = String(orderDelivery?.weight) || null
			inputsWeightAndCount.sizeLength = orderDelivery?.sizeLength ? String(orderDelivery?.sizeLength) : null
			inputsWeightAndCount.sizeWidth = orderDelivery?.sizeWidth ? String(orderDelivery?.sizeWidth) : null
			inputsWeightAndCount.sizeHeight = orderDelivery?.sizeHeight ? String(orderDelivery?.sizeHeight) : null
			inputsWeightAndCount.transporterId = orderDelivery?.transporter?.name || null
			inputsWeightAndCount.tracking = orderDelivery?.tracking || null
			inputsWeightAndCount.price = orderDelivery?.price ? String(orderDelivery?.price) : null
			inputsWeightAndCount.postalCode = orderDelivery?.postalCode || null
			inputsWeightAndCount.region = orderDelivery?.region || null
			inputsWeightAndCount.city = orderDelivery?.city || null
			inputsWeightAndCount.address = orderDelivery?.address || null
			transporters.value = await fetchTransports()
		})

		async function fetchTransports() {
			const res = await axios.get<Array<ITransporter>>('/internal/get-all-transporter')
			return res.data
		}

		const isManagerDeliveryMethod = computed(() => order?.value?.ordersDeliveries[0].type === enumDeliveryTypes.manager)

		const isValidInputsWeightAndCount = computed(() => {
			let keys = ['countBoxes', 'weightBoxes']

			return keys.every(key => inputsWeightAndCount[key as keyof IWeightCountBox])
		})

		function validateNumberBoxes(val: string, key: keyof IWeightCountBox) {
			val = val.replace(/[^1-9]/, '')

			inputsWeightAndCount[key] = Number(val) > (order?.value.units || 0) ? inputsWeightAndCount[key] : val
		}

		function validatePrice(val: string) {
			return !!val.match(/^\d*(\.\d{1,2})?$/)
		}

		function validateNumberWeight(val: string, key: keyof IWeightCountBox) {
			val = val.replace(/[^0-9]/, '')

			inputsWeightAndCount[key] = val || null
		}

		async function createDoc() {
			if (!checkRole) return
			const allowedRoles = [7, 9]
			let isUserCanChange = false
			for (const userRoleId of userRoleIds) {
				if (allowedRoles.includes(userRoleId)) {
					isUserCanChange = true
				}
			}
			if (!isUserCanChange) checkRole(false)
			if (isManagerDeliveryMethod.value && inputsWeightAndCount.price && !validatePrice(inputsWeightAndCount.price)) {
				alert('Не валидная цена')
				return
			}
			isFetchingWeightCountBoxes.value = true
			let res: undefined | AxiosResponse<ICollectOrder>
			if (isManagerDeliveryMethod.value) {
				const data = {
					orderDeliveryId: order?.value.ordersDeliveries[0].id || '',
					orderId: Number(order?.value.id),
					weight: Number(inputsWeightAndCount.weightBoxes) || null,
					count: Number(inputsWeightAndCount.countBoxes) || null,
					sizeLength: Number(inputsWeightAndCount.sizeLength) || null,
					sizeWidth: Number(inputsWeightAndCount.sizeWidth) || null,
					sizeHeight: Number(inputsWeightAndCount.sizeHeight) || null,
					transporterId: Number(inputsWeightAndCount.transporterId) || null,
					tracking: inputsWeightAndCount.tracking,
					price: Number(inputsWeightAndCount.price),
					postalCode: inputsWeightAndCount.postalCode,
					region: inputsWeightAndCount.region,
					city: inputsWeightAndCount.city,
					address: inputsWeightAndCount.address,
					canUpdateOrderState: order?.value.state === enumOrdersState.collected
				}
				res = await apiSetBoxesWeightCreateOrderDeliveryManager(data)
			} else {
				const data = {
					orderDeliveryId: order?.value.ordersDeliveries[0].id || '',
					orderId: Number(order?.value.id),
					weight: Number(inputsWeightAndCount.weightBoxes),
					count: Number(inputsWeightAndCount.countBoxes)
				}

				if (order?.value.ordersDeliveries[0].type === enumDeliveryTypes.cdek) {
					res = await apiSetBoxesWeightCreateOrderDelivery(data)
				} else res = await apiSetBoxesWeightCreateOrderDeliveryWarehouse(data)
			}
			if (res && order?.value) order.value = res.data

			isFetchingWeightCountBoxes.value = false
		}

		async function apiSetBoxesWeightCreateOrderDeliveryWarehouse(data: {
			orderDeliveryId: string
			orderId: number
			weight: number
			count: number
		}) {
			try {
				return await axios.post<ICollectOrder>('/order/set-boxes-weight-create-delivery-warehouse', data)
			} catch (e) {
				ElMessage.error('Не удалось создать накладную')
			}
		}

		async function apiSetBoxesWeightCreateOrderDeliveryManager(data: any) {
			try {
				return await axios.post<ICollectOrder>('/order/set-boxes-weight-create-delivery-manager', data)
			} catch (e) {
				ElMessage.error('Не удалось создать накладную')
			}
		}

		async function apiSetBoxesWeightCreateOrderDelivery(data: {
			orderDeliveryId: string
			orderId: number
			weight: number
			count: number
		}) {
			try {
				return await axios.post<ICollectOrder>('/order/set-boxes-weight-create-delivery', data)
			} catch (e) {
				ElMessage.error('Не удалось создать накладную')
			}
		}

		function onCheckboxBox(numberBox: number) {
			if (!checkRole) return
			const allowedRoles = [7, 9]
			let isUserCanChange = false
			for (const userRoleId of userRoleIds) {
				if (allowedRoles.includes(userRoleId)) {
					isUserCanChange = true
				}
			}
			if (!isUserCanChange) checkRole(false)
			const idx = checkboxesBox.value.indexOf(numberBox)

			if (idx === -1) checkboxesBox.value.push(numberBox)
			else checkboxesBox.value.splice(idx, 1)

			checkAllBoxesReady()
		}

		function checkAllBoxesReady() {
			if (checkboxesBox.value.length === order?.value.ordersDeliveries[0].boxes) fullOrderToSending()
		}

		async function fullOrderToSending() {
			isFetchingFullOrderToSending.value = true

			const res = await apiFullOrderToSending(Number(order?.value.id) || 0)
			if (res && order?.value) order.value = res.data

			isFetchingFullOrderToSending.value = false
		}

		async function apiFullOrderToSending(orderId: number) {
			try {
				return await axios.get<ICollectOrder>('/storage/packing-order-to-sending-by-id/' + orderId)
			} catch (e) {
				ElMessage.error(e)
			}
		}

		async function successMovingOrderBoxes() {
			try {
				const res = await apiGetOrderById?.(Number(order?.value.id))
				if (res && order?.value) order.value = res
			} catch (e) {
				ElMessage.error('Не удалось обновить заказ, обновите страницу')
			}
		}

		function getLocalizeDeliveryType(type: enumDeliveryTypes) {
			const key = enumDeliveryTypes[type] as keyof typeof enumDeliveryTypes
			return localizeDeliveryTypesDoc[key]
		}

		function printBox(boxNumber: number) {
			const orderDelivery = order?.value.ordersDeliveries[0]
			const fullAddress =
				orderDelivery?.type === enumDeliveryTypes.warehouse
					? '-'
					: orderDelivery?.region + ' ' + orderDelivery?.city + ' ' + (orderDelivery?.address || '')

			const userInfo: { Phone: string | undefined; Name: string | undefined } = JSON.parse(
				orderDelivery?.internalDetails || ''
			)

			let typeDelivery = getLocalizeDeliveryType(orderDelivery?.type || -1) || '-'

			if (isManagerDeliveryMethod.value && Array.isArray(transporters.value)) {
				const transporter = transporters.value.find(transporter => transporter.id === orderDelivery?.transporterId)
				const transporterName = transporter?.name
				if (transporterName) {
					typeDelivery = transporterName
				}
			}

			const propsForPrint: IPrintDocOrder = {
				boxNumber,
				fullAddress,
				phone: userInfo?.Phone || '-',
				recipient: userInfo?.Name || '-',
				orderId: Number(order?.value.id),
				countBox: orderDelivery?.boxes || '-',
				trackNumber: orderDelivery?.tracking || '-',
				typeDelivery
			}

			const orderDoc = getOrderDoc(propsForPrint)

			const popupWindow = window.open()
			popupWindow?.document.write(orderDoc)

			setTimeout(function () {
				popupWindow?.focus()
				popupWindow?.print()
				popupWindow?.close()
			}, 0)
		}

		return {
			order,
			enumOrdersState,
			transporters,
			inputsWeightAndCount,
			isFetchingWeightCountBoxes,
			isValidInputsWeightAndCount,
			isManagerDeliveryMethod,
			isPrintDoc,
			checkboxesBox,
			isFetchingFullOrderToSending,
			isDisabledCheckboxesBox,
			isReadySteps,
			validateNumberWeight,
			validateNumberBoxes,
			createDoc,
			onCheckboxBox,
			successMovingOrderBoxes,
			printBox
		}
	}
})
